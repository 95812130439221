import { Component, Input, output } from '@angular/core';
import { NavLink } from '@welma-education/common';
import { RouterModule } from '@angular/router';
import { HasPermissionDirective } from '../../../../shared/directives/has-permission.directive';

@Component({
  standalone: true,
  selector: 'lgs-navigation',
  templateUrl: './navigation.component.html',
  imports: [RouterModule, HasPermissionDirective],
})
export class NavigationComponent {
  menuItemClick = output<MouseEvent>();
  @Input() links: (NavLink & { permission: string; svgPathData: string })[] = [];
}
