import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { IconModule, icons } from '@welma-education/ui';
import { routes } from './routes';
import { API_URL } from '@welma-education/common';
import { environment, provideLgsConfiguration } from './environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: 'fr',
    },
    {
      provide: API_URL,
      useValue: environment.apiURL,
    },
    importProvidersFrom(IconModule.register(icons)),
    importProvidersFrom(
      AuthModule.forRoot({
        domain: environment.auth0.DOMAIN,
        clientId: environment.auth0.CLIENT_ID,
        authorizationParams: {
          audience: environment.auth0.AUDIENCE,
          redirect_uri: window.location.origin,
        },
        httpInterceptor: {
          allowedList: [`${environment.apiURL}/*`, `/api/*`],
        },
      }),
    ),
    provideLgsConfiguration(),
  ],
};
