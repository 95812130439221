import { Routes } from '@angular/router';

export const TEACHERS_ROUTES: Routes = [
  {
    path: 'create',
    loadComponent: () =>
      import('../teachers/pages/teacher-create').then((c) => c.TeacherCreatePage),
  },
  {
    path: ':teacherId',
    loadComponent: () =>
      import('../teachers/pages/teacher-details').then((c) => c.TeacherDetailsPage),
  },
  {
    path: ':teacherId/edit/academics',
    loadComponent: () =>
      import('../teachers/pages/teacher-edit-academics').then((c) => c.TeacherEditAcademicsPage),
  },
  {
    path: ':teacherId/edit/career',
    loadComponent: () =>
      import('../teachers/pages/teacher-edit-career').then((c) => c.TeacherEditCareerPage),
  },
  {
    path: ':teacherId/edit/contact',
    loadComponent: () =>
      import('../teachers/pages/teacher-edit-contact').then((c) => c.TeacherEditContactPage),
  },
  {
    path: ':teacherId/edit/passport',
    loadComponent: () =>
      import('./pages/teacher-edit-passport').then((c) => c.TeacherEditPassportPage),
  },
  {
    path: ':teacherId/edit/subjects',
    loadComponent: () =>
      import('../teachers/pages/teacher-edit-subjects').then((c) => c.TeacherEditSubjectsPage),
  },
  {
    path: ':teacherId/edit/picture',
    loadComponent: () =>
      import('../teachers/pages/teacher-edit-picture').then((c) => c.TeacherEditPicturePage),
  },
  {
    path: '',
    loadComponent: () => import('../teachers/pages/teacher-list').then((c) => c.TeacherListPage),
  },
  { path: '**', redirectTo: '' },
];
