import {
  animate,
  animateChild,
  AnimationTriggerMetadata,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TransitionDuration, TransitionEasing } from '@welma-education/ui';

export const sidebarShellAnimations: {
  readonly ngIf: AnimationTriggerMetadata;
  readonly showHideOpacity: AnimationTriggerMetadata;
  readonly showHideTranslate: AnimationTriggerMetadata;
} = {
  ngIf: trigger('ngIf', [
    transition(':enter, :leave', [query('@*', animateChild(), { optional: true })]),
  ]),
  showHideOpacity: trigger('showHideOpacity', [
    transition(':enter', [
      style({
        opacity: 0,
      }),
      animate(
        `${TransitionDuration.DURATION_300} ${TransitionEasing.EASE_LINEAR}`,
        style({ opacity: 1 }),
      ),
    ]),
    transition(':leave', [
      style({
        opacity: 1,
      }),
      animate(
        `${TransitionDuration.DURATION_300} ${TransitionEasing.EASE_LINEAR}`,
        style({ opacity: 0 }),
      ),
    ]),
  ]),
  showHideTranslate: trigger('showHideTranslate', [
    transition(':enter', [
      style({
        transform: 'translateX(-100%)',
      }),
      animate(
        `${TransitionDuration.DURATION_300} ${TransitionEasing.EASE_IN_OUT}`,
        style({ transform: 'translateX(0)' }),
      ),
    ]),
    transition(':leave', [
      style({
        transform: 'translateX(0)',
      }),
      animate(
        `${TransitionDuration.DURATION_300} ${TransitionEasing.EASE_IN_OUT}`,
        style({ transform: 'translateX(-100%)' }),
      ),
    ]),
  ]),
};
